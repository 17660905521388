import React from "react"
import Layout from "../components/layout"
import PostCard from "../components/post-card"
import { graphql } from "gatsby"

export default function PageTemplate({ data, location }) {
  const meta = {
    title: `Blog`,
    description: `Articles to help you build, launch and develop your business focused on products`,
    publicationDate: `2020-02-01`,
    lastUpdated: `2020-02-01`,
  }
  return (
    <Layout 
      title={meta.title}
      description={meta.description}
      pathname={location.pathname}
      publicationDate={meta.publicationDate}
      lastUpdated={meta.lastUpdated}>
        <div className="px-12 py-10 md:py-8">
          <div className="md:max-w-6xl md:mx-auto">
          <h3 className="pb-4 font-bold md:text-xl">Read</h3>
          <h2 className="w-2/3 pb-4 font-light text-2xl md:text-4xl leading-tight tracking-tighter">
            {meta.description}
          </h2>
      <div className="md:flex md:flex-wrap mb-4">
        {data.allMdx.edges.map(({ node }) => (
        <div key={node.id} className="w-full md:w-1/3 p-4">
            <PostCard 
              title={node.frontmatter.title}
              excerpt={node.frontmatter.excerpt}
              slug={node.frontmatter.slug}
              image={node.frontmatter.featuredImage.childImageSharp.fluid}>
            </PostCard>
        </div>
        ))}
      </div>
      </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql `
query AllBlogPosts {
  allMdx(
    filter: {frontmatter: {status: {eq: "published"}, type: {eq: "blog-post"}}}
    sort: {fields: frontmatter___lastUpdated, order: DESC}) {
    edges {
      node {
        id
        frontmatter {
          title
          slug
          excerpt
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 530, maxHeight: 530, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
}
`